import { TYPE_MODAL } from '@/pages/agents/model';
import {
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { TODO } from '.';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { AgentUpdateDataType } from '@/apis/service';
export interface IPermissions {
  permission_code?: string;
  permission_name?: string;
}

export interface IRole {
  role_code: string;
  role_name: string;
}

export interface IpDetail {
  ip: string;
  note: string;
}
export interface IAgentDetail {
  permissions: Array<IPermissions>;
  role: IRole;
  contact: string;
  status: string;
  agent_id: string;
  agent_name: string;
  point_rate: number;
  point_balance: number;
  cash_balance: number;
  commission_balance?: number;
  currency_code?: string;
  alert_on_balance: string;
  whitelist_ip: Array<IpDetail>;
  created_date?: string;
  oper_mode?: string;
  bank_name?: string;
  account_number?: string;
  account_holder?: string;
  userInfo?: string;
  api_url?: string;
  api_token?: string;
  callback_url?: string;
  callback_token?: string;
  is_alert_enabled?: boolean;
}

export interface IAgentDetailProps {
  openModal: (type: TYPE_MODAL) => void;
  updateAgent: (data: AgentUpdateDataType) => void;
}

export interface IAgentTreeModal {
  show: boolean;
  closeModal: () => void;
  title: string;
  reset: UseFormReset<Record<string, string>>;
}

export interface IChangePasswordForm {
  register: UseFormRegister<TODO>;
  setValue: UseFormSetValue<TODO>;
  errors: FieldErrors;
  getValues: UseFormGetValues<TODO>;
  watch: UseFormWatch<TODO>;
  reset: UseFormReset<TODO>;
}

export interface IChangeAccessControlForm {
  reset: UseFormReset<TODO>;
}

export enum QUERY_KEYS_AGENT {
  QUERY_KEY_AGENT_TABLE = 'agents/list',
  QUERY_KEY_AGENT_PROFILE_INFO = 'agents/profileInfo',
}

export interface IUseCreateAgentMutation {
  callback?: VoidFunction;
}
export interface IUseUpdateAgentMutation {
  callback?: VoidFunction;
}

export interface IDataApproveRefund {
  transId: string;
}

export interface IPointDepositRegister extends FieldValues {
  requestPoint: number;
  note: string;
}
